import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import services from "./apiServices";
import "../css/Navbar.css"; // Ensure you have the CSS in this file
// import { sessionkill } from './Sessionkill';

export default function Navbar(props) {
  const [time, setTime] = useState(1800); // 30 minutes in seconds
  const [hasRequested, setHasRequested] = useState(false);
  const location = useLocation(); // Get the current location

  // Determine the active tab based on the current location
  const getActiveTab = () => {
    switch (location.pathname) {
      case "/":
        return "dashboard";

      case "/emp_lev_app":
      case "/obo_emp_lev_app":
        return "application";

      case "/cust_rep_lve_bal_consume":
      case "/cust_rep_lve_apl_print":
      case "/cust_rep_lve_apl_print":
      case "/manager_rpt_team_mem_list":
      case "/manager_agine_rpt_of_lve":
      case "/manager_rpt_lve_apl_print":
      case "/manager_rpt_team_mem_le_bal_consm":
        return "reports";

      case "/adm_add_usr":
        return "admin";

      case "/log_out":
        return "logout"

      default:
        return "";
    }
  };

  const [activeTab, setActiveTab] = useState(getActiveTab());

  useEffect(() => {
    setActiveTab(getActiveTab()); // Update active tab on location change
  }, [location]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(intervalId);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
      2,
      "0"
    )}`;
  };

  useEffect(() => {
    let timer;
    if (time > 0 && !hasRequested) {
      timer = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (time === 0 && !hasRequested) {
      // Only execute this block if the timer reaches 0 and logout hasn't been requested
      const performLogout = async () => {
        try {
          console.log("Session timeout block");
          await services.sessionkill(); // Call the logout function
          setHasRequested(true); // Mark that the logout request has been made

          window.location.href = "/"; // Redirect to homepage after logout
          // 3 seconds delay
        } catch (error) {
          console.error("Logout failed", error.message); // Handle errors if needed
        }
      };

      performLogout();
    }

    return () => clearInterval(timer); // Cleanup interval on component unmount
  }, [time, hasRequested]);

  // console.log(props.nav1)
  return props.nav == "O" ? (
    <>
      <header class="fixedheader pd1 ">
        <div class="container_12">
          <div class="grid_12">
            <div class="wrapper">
              {" "}
              <Link to="index.html" class="logo">
                design.maniacs
              </Link>
              <div class="nav">
                <div class="subnav active">
                  <button class="subnavbtn">
                    Dashboard<i class="fa fa-caret-down"></i>
                  </button>
                </div>
                <div class="subnav">
                  <button class="subnavbtn">
                    Client Configuration<i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <a href="#company">Client Creation</a>
                    <a href="#team">System Configuration</a>
                    <a href="#team">User Creation(obo)</a>
                    <a href="#team">Leave Status</a>
                  </div>
                </div>
                <div class="subnav">
                  <button class="subnavbtn">
                    Profile <i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <a href="#bring">User Profile</a>
                    <a href="#deliver">Change Password</a>
                  </div>
                </div>
                <div class="subnav">
                  <button class="subnavbtn">
                    Admin<i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <a href="#link1">Leave Balance</a>
                    <a href="#link2">Agine Analyasis</a>
                    <a href="#link3">Leave Consumption</a>
                    <a href="#link4">Team Member List</a>
                    <a href="#link2">Team Leave Balance</a>
                    <a href="#link3">Team Leave Consumption</a>
                    <a href="#link4">Application Print</a>
                  </div>
                </div>
                <div class="subnav">
                  <button class="subnavbtn">
                    Reports<i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <a href="#link1">Leave Policy</a>
                    <a href="#link2">Holiday Calendar</a>
                  </div>
                </div>
                <div class="subnav">
                  <button class="subnavbtn">
                    Logout <i class="fa fa-caret-down"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row wrapper pd1" style={{ marginBottom: ".1%" }}>
          <div className="column " style={{ marginLeft: "2%" }}>
            <b>User {props.nav1}</b>
          </div>

          <div
            className="column"
            style={{ textAlign: "right", marginRight: "2%" }}
          >
            <b>{formatTime(time)}</b>
          </div>
        </div>
      </header>
    </>
  ) : props.nav == "C" ? (
    props.post === "MNGR" ? (
      <>
        <header class="fixedheader pd1 ">
          <div class="container_12">
            <div class="grid_12">
              <div class="wrapper">
                {" "}
                <Link to="index.html" class="logo">
                  Sutti
                </Link>
                <div class="nav">
                  <div className={`subnav ${activeTab === "dashboard" ? "active" : ""}`}>
                    <Link to="/" className="subnavbtn" onClick={() => setActiveTab("dashboard")}>
                      Dashboard
                    </Link>
                  </div>
                  <div class="subnav">
                    <button class="subnavbtn">
                      Application<i class="fa fa-caret-down"></i>
                    </button>
                    <div class="subnav-content">
                      <Link to="/emp_lev_app">Application</Link>
                      <Link to="/obo_emp_lev_app">Application OBO</Link>
                    </div>
                  </div>
                  <div class="subnav">
                    <button class="subnavbtn">
                      Profile <i class="fa fa-caret-down"></i>
                    </button>
                    <div class="subnav-content">
                      <a href="#bring">User Profile</a>
                      <a href="#deliver">Change Password</a>
                    </div>
                  </div>

                  <div className={`subnav ${activeTab === "reports" ? "active" : ""}`}>
                    <button className="subnavbtn">
                      Reports<i className="fa fa-caret-down"></i>
                    </button>

                    <div class="subnav-content">
                      <Link to="/cust_rep_lve_bal_consume" onClick={() => setActiveTab("reports")}>Leave Bal. and Consume</Link>
                      <Link to="/manager_agine_rpt_of_lve" onClick={() => setActiveTab("reports")}>Aging Analyasis</Link>
                      <Link to="/manager_rpt_team_mem_list" onClick={() => setActiveTab("reports")}>Team members</Link>
                      <Link to="/manager_rpt_team_mem_le_bal_consm" onClick={() => setActiveTab("reports")} >Team members Leave Bal. & Consume</Link>
                      <Link to="/manager_rpt_lve_apl_print" onClick={() => setActiveTab("reports")}>Applications Print </Link>
                    </div>
                  </div>
                  <div class="subnav">
                    <button class="subnavbtn">
                      Policy<i class="fa fa-caret-down"></i>
                    </button>
                    <div class="subnav-content">
                      <a href="#link1">Leave Policy</a>
                      <a href="#link2">Holiday Calendar</a>
                    </div>
                  </div>

                  <div class="subnav">
                    <button class="subnavbtn">
                      Logout <i class="fa fa-caret-down"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row wrapper pd1" style={{ marginBottom: ".1%" }}>
            <div className="column " style={{ marginLeft: "2%" }}>
              <b>User {props.nav1}</b>
            </div>

            <div
              className="column"
              style={{ textAlign: "right", marginRight: "2%" }}
            >
              <b>{formatTime(time)}</b>
            </div>
          </div>
        </header>
      </>
    ) : (
      <>
        <header class="fixedheader pd1 ">
          <div class="container_12">
            <div class="grid_12">
              <div class="wrapper">
                {" "}
                <Link to="index.html" class="logo">
                  Sutti
                </Link>
                <div className="nav">
                  <div className={`subnav ${activeTab === "dashboard" ? "active" : ""}`}>
                    <Link to="/" className="subnavbtn" onClick={() => setActiveTab("dashboard")}>
                      Dashboard
                    </Link>
                  </div>
                  <div className={`subnav ${activeTab === "application" ? "active" : ""}`}>
                    <button className="subnavbtn">
                      Application<i className="fa fa-caret-down"></i>
                    </button>
                    <div className="subnav-content">
                      <Link to="/emp_lev_app" onClick={() => setActiveTab("application")}>Application</Link>
                      <Link to="/obo_emp_lev_app" onClick={() => setActiveTab("application")}>Application OBO</Link>
                    </div>
                  </div>
                  <div className={`subnav ${activeTab === "profile" ? "active" : ""}`}>
                    <button className="subnavbtn">
                      Profile <i className="fa fa-caret-down"></i>
                    </button>
                    <div className="subnav-content">
                      <a href="#bring">User  Profile</a>
                      <a href="#deliver">Change Password</a>
                    </div>
                  </div>
                  <div className={`subnav ${activeTab === "reports" ? "active" : ""}`}>
                    <button className="subnavbtn">
                      Reports<i className="fa fa-caret-down"></i>
                    </button>
                    <div className="subnav-content">
                      <Link to="/cust_rep_lve_bal_consume" onClick={() => setActiveTab("reports")}>Leave Balance and Consumption</Link>
                      <Link to="/cust_rep_lve_apl_print" onClick={() => setActiveTab("reports")}>Applications Print</Link>
                    </div>
                  </div>
                  <div className={`subnav ${activeTab === "policy" ? "active" : ""}`}>
                    <button className="subnavbtn">
                      Policy<i className="fa fa-caret-down"></i>
                    </button>
                    <div className="subnav-content">
                      <Link to="/#" onClick={() => setActiveTab("policy")}>Leave Policy</Link>
                      <Link to="/#" onClick={() => setActiveTab("policy")}>Holiday Calendar</Link>
                    </div>
                  </div>
                  <div className={`subnav ${activeTab === "logout" ? "active" : ""}`}>
                    <Link to="/log_out" className="subnavbtn" onClick={() => setActiveTab("logout")}>
                      Logout
                    </Link>
                  </div>
                </div>
                {/* <div class="nav">
                  <div className="subnav active">
                    <Link to="/" className="subnavbtn">
                      Dashboard
                    </Link>
                  </div>
                  <div class="subnav">
                    <button class="subnavbtn">
                      Application<i class="fa fa-caret-down"></i>
                    </button>
                    <div class="subnav-content">
                      <Link to="/emp_lev_app">Application</Link>
                      <Link to="/obo_emp_lev_app">Application OBO</Link>
                    </div>
                  </div>
                  <div class="subnav">
                    <button class="subnavbtn">
                      Profile <i class="fa fa-caret-down"></i>
                    </button>
                    <div class="subnav-content">
                      <a href="#bring">User Profile</a>
                      <a href="#deliver">Change Password</a>
                    </div>
                  </div>
                  <div class="subnav">
                    <button class="subnavbtn">
                      Admin<i class="fa fa-caret-down"></i>
                    </button>
                    <div class="subnav-content">
                      <a href="#link1">Leave Balance</a>
                      <a href="#link2">Agine Analyasis</a>
                      <a href="#link3">Leave Consumption</a>
                      <a href="#link4">Team Member List</a>
                      <a href="#link2">Team Leave Balance</a>
                      <a href="#link3">Team Leave Consumption</a>
                      <a href="#link4">Application Print</a>
                    </div>
                  </div>
                  <div class="subnav">
                    <button class="subnavbtn">
                      Reports<i class="fa fa-caret-down"></i>
                    </button>
                    <div class="subnav-content">
                      <Link to="/cust_rep_lve_bal_consume" >Leave Balance and Consumption</Link>
                      <Link to="/cust_rep_lve_apl_print" >Applications Print </Link>
                    </div>
                  </div>
                  <div class="subnav">
                    <button class="subnavbtn">
                      Policy<i class="fa fa-caret-down"></i>
                    </button>
                    <div class="subnav-content">
                      <a href="#link1">Leave Policy</a>
                      <a href="#link2">Holiday Calendar</a>
                    </div>
                  </div>

                  <div class="subnav">
                    <button class="subnavbtn">
                      Logout <i class="fa fa-caret-down"></i>
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
          </div >
          <div class="row wrapper pd1" style={{ marginBottom: ".1%" }}>
            <div className="column " style={{ marginLeft: "2%" }}>
              <b>User {props.nav1}</b>
            </div>

            <div
              className="column"
              style={{ textAlign: "right", marginRight: "2%" }}
            >
              <b>{formatTime(time)}</b>
            </div>
          </div>
        </header >
      </>
    )
  ) : props.nav == "Adm" ? (
    <>
      <header class="fixedheader pd1 ">
        <div class="container_12">
          <div class="grid_12">
            <div class="wrapper">
              {" "}
              <Link to="index.html" class="logo">
                Sutti
              </Link>
              <div class="nav">
                <div class="subnav ">
                  <Link to="/" class="subnavbtn">
                    Dashboard<i class="fa fa-caret-down"></i>
                  </Link>
                </div>
                <div class="subnav">
                  <button class="subnavbtn">
                    Application<i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <Link to="/emp_lev_app">Application</Link>
                    <Link to="/obo_emp_lev_app">Application OBO</Link>
                  </div>
                </div>
                <div class="subnav">
                  <button class="subnavbtn">
                    Profile <i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <a href="#bring">User Profile</a>
                    <a href="#deliver">Change Password</a>
                  </div>
                </div>
                <div class="subnav">
                  <button class="subnavbtn">
                    Admin <i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <Link to="/adm_add_usr">Add User</Link>
                    <Link to="#deliver">Policy Managment</Link>
                    <Link to="#deliver">Post Managment</Link>
                    <Link to="#deliver">User Rights</Link>
                    <Link to="#deliver">Add/Edit Holliday Calendar</Link>
                    <Link to="#deliver">Leave Configuration</Link>
                    <Link to="#deliver">Add/Edit Annocements</Link>
                    <Link to="#deliver">Change/Reset Password</Link>
                    <Link to="#deliver">Month End Procedure</Link>
                    <Link to="#deliver">Year End Procedure</Link>
                  </div>
                </div>
                <div className={`subnav ${activeTab === "reports" ? "active" : ""}`}>
                  <button class="subnavbtn">
                    Reports<i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <Link to="/cust_rep_lve_bal_consume" onClick={() => setActiveTab("reports")}>Leave Bal. and Consume</Link>
                    <Link to="/#" onClick={() => setActiveTab("reports")}>Agine Analyasis</Link>
                    <Link to="/manager_rpt_team_mem_list" >Team Members</Link>
                    <Link to="/manager_rpt_team_mem_le_bal_consm" onClick={() => setActiveTab("reports")} >Team members Leave Bal. and Consume</Link>
                    <Link to="/#" onClick={() => setActiveTab("reports")} >Leave types. Post, Status</Link>
                    <Link to="/adm_rpt_all_emp_lve_dtls" onClick={() => setActiveTab("reports")} >All Employees & Leave Details </Link>
                    <Link to="/adm_rpt_todays_lev_emp_list" onClick={() => setActiveTab("reports")} >Leave On Today Employees </Link>
                    <Link to="/manager_rpt_lve_apl_print" onClick={() => setActiveTab("reports")} >Application Print</Link>
                  </div>
                </div>
                <div class="subnav">
                  <button class="subnavbtn">
                    Policy<i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <a href="#link1">Leave Policy</a>
                    <a href="#link2">Holiday Calendar</a>
                  </div>
                </div>
                <div class="subnav">
                  <button class="subnavbtn">
                    Logout <i class="fa fa-caret-down"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row wrapper pd1" style={{ marginBottom: ".1%" }}>
          <div className="column " style={{ marginLeft: "2%" }}>
            <b>User {props.nav1}</b>
          </div>

          <div
            className="column"
            style={{ textAlign: "right", marginRight: "2%" }}
          >
            <b>{formatTime(time)}</b>
          </div>
        </div>
      </header>
    </>
  ) : props.nav == "A" ? (
    <>
      <header class="fixedheader pd1 ">
        <div class="container_12">
          <div class="grid_12">
            <div class="wrapper">
              {" "}
              <Link to="index.html" class="logo">
                design.maniacs
              </Link>
              <div class="nav">
                <div class="subnav active">
                  <button class="subnavbtn">
                    Dashboard Approvar<i class="fa fa-caret-down"></i>
                  </button>
                </div>
                <div class="subnav">
                  <button class="subnavbtn">
                    Application<i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <Link to="/emp_lev_app">Application</Link>
                    <Link to="/obo_emp_lev_app">Application OBO</Link>
                  </div>
                </div>
                <div class="subnav">
                  <button class="subnavbtn">
                    Profile <i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <a href="#bring">User Profile</a>
                    <a href="#deliver">Change Password</a>
                  </div>
                </div>
                <div class="subnav">
                  <button class="subnavbtn">
                    Reports<i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <a href="#link1">Leave Balance</a>
                    <a href="#link2">Agine Analyasis</a>
                    <a href="#link3">Leave Consumption</a>
                    <a href="#link4">Team Member List</a>
                    <a href="#link2">Team Leave Balance</a>
                    <a href="#link3">Team Leave Consumption</a>
                    <a href="#link4">Application Print</a>
                  </div>
                </div>
                <div class="subnav">
                  <button class="subnavbtn">
                    Policy<i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <a href="#link1">Leave Policy</a>
                    <a href="#link2">Holiday Calendar</a>
                  </div>
                </div>
                <div class="subnav">
                  <button class="subnavbtn">
                    Logout <i class="fa fa-caret-down"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row wrapper pd1" style={{ marginBottom: ".1%" }}>
          <div className="column " style={{ marginLeft: "2%" }}>
            <b>User {props.nav1}</b>
          </div>

          <div
            className="column"
            style={{ textAlign: "right", marginRight: "2%" }}
          >
            <b>{formatTime(time)}</b>
          </div>
        </div>
      </header>
    </>
  ) : (
    ""
  );
}

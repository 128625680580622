// import React from 'react'
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import services from "./apiServices";
export default function LoginForm(props) {
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({});
  const [formData, setFormData] = useState({
    org_ucd: "",
    usr_name: "",
    usr_pass: "",
  });
  console.log(formData);

  const validateForm = () => {
    const newErrors = {};

    // Check each field for emptiness
    if (!formData.org_ucd) newErrors.org_ucd = "Orgnization Code is required";
    if (!formData.usr_name) newErrors.usr_name = "User Name is required";
    if (!formData.usr_pass) newErrors.usr_pass = "User Password is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };
  const submit_login = () => {
    if (validateForm()) {
      const usr_login = async () => {
        try {
          const usr_log = await services.usr_login(formData);

          //Adjust this based on actual data structure
          if (usr_log) {
            console.log(usr_log); // Store the relevant data in session storage for session management

            sessionStorage.setItem("user_data", JSON.stringify(usr_log));

            props.ut(usr_log[0].NTT_TYP_FKY);
            props.dnm(usr_log[0].USR_DISPLAY_NAME);
            props.usr_post(usr_log[0].USR_POST);
            // console.log(usr_log[0].USR_DISPLAY_NAME);
            console.log("Login successfully"); // Wrap it in an object if necessary
          } else {
            alert("Login failed");
          }
        } catch (error) {
          alert("Login failed");
        }
      };
      usr_login();
    }
  };

  console.log(data);
  return (
    <>
      <div class="wrapper_form wd_log">
        <div class="title">Login Form</div>
        <form method="Post">
          <br />
          <div class="field">
            <input
              type="text"
              name="org_ucd"
              value={formData.org_ucd || ""}
              placeholder="enter Organization Unique Code"
              onChange={(e) =>
                setFormData({ ...formData, org_ucd: e.target.value })
              }
            />
            <label>
              Organization Unique Code{" "}
              {errors.org_ucd && (
                <span className="error-message">{errors.org_ucd}</span>
              )}{" "}
            </label>
          </div>
          <br />
          <div class="field">
            <input
              type="text"
              name="usr_name"
              value={formData.usr_name || ""}
              placeholder="enter user Name"
              onChange={(e) =>
                setFormData({ ...formData, usr_name: e.target.value })
              }
            />
            <label>
              User Name{" "}
              {errors.usr_name && (
                <span className="error-message">{errors.usr_name}</span>
              )}{" "}
            </label>
          </div>
          <br />

          <div class="field">
            <input
              type="password"
              name="usr_pass"
              placeholder="enter password"
              value={formData.usr_pass || ""}
              onChange={(e) =>
                setFormData({ ...formData, usr_pass: e.target.value })
              }
            />
            <label>
              Password{" "}
              {errors.usr_pass && (
                <span className="error-message">{errors.usr_pass}</span>
              )}
            </label>
          </div>
          {/* <div class="content">

                  <div class="pass-link">
                     <Link to="/forgotpass">Forgot password?</Link>
                  </div>
               </div> */}

          <div class="field">
            <button
              style={{ width: "40%", float: "right" }}
              type="button"
              className="btn-action action-button"
              onClick={submit_login}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
